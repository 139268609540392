<template>
  <AuthLayout>
    <template #info>
      <InfoPanel title="Sign Up Today" subtitle="Get 30 Days Free" />
    </template>
    <template #form>
      <h3 class="text-sm mb-4 font-semibold">Sign Up for Free</h3>
      <ToznyForm
        :error="showError"
        :loading="loading"
        :onSubmit="btnRegister"
        class="register-form"
      >
        <ToznyInput
          v-model="fullName"
          id="fullName"
          name="fullName"
          class="mb-6"
          placeholder="Jane Doe"
          label="Full Name"
          float-label
        />
        <ToznyInput
          v-model="email"
          id="email"
          name="email"
          class="mb-6"
          placeholder="name@example.com"
          label="Email"
          float-label
        />
        <ToznyInput
          v-model="password"
          type="password"
          id="password"
          name="password"
          class="mb-6"
          label="Password"
          float-label
        />
        <ToznyInput
          v-model="confirmPassword"
          type="password"
          id="confirmPassword"
          name="confirmPassword"
          class="mb-6"
          label="Password Confirm"
          float-label
        />
        <div class="text-gray-dark text-tiny text-right mb-12">
          Already have an account?
          <a
            href="#!"
            class="no-underline text-tozny"
            @click.prevent="routeToLogin"
            >Log In</a
          >
        </div>
        <ToznyButton
          class="register-account-button w-full py-4 rounded-lg"
          buttonText="Create Account"
          :loading="loading"
        />
      </ToznyForm>
      <PaperKeyDialogue
        :showing="showing"
        @continue="onContinue"
        :paperKey="paperKey"
        class="z-10"
      />
    </template>
  </AuthLayout>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import AuthLayout from '../../Common/AuthLayout/AuthLayout'
import InfoPanel from '../../Common/AuthLayout/InfoPanel'
import PaperKeyDialogue from './PaperKeyDialogue'
import ToznyForm from '../../Common/ToznyForm'
import ToznyButton from '../../Common/ToznyButton'
import ToznyInput from '../../Common/ToznyInput'
import { validatePassword } from '@/utils/utils'
export default {
  name: 'Register',
  components: {
    AuthLayout,
    InfoPanel,
    PaperKeyDialogue,
    ToznyForm,
    ToznyButton,
    ToznyInput,
  },
  data: function () {
    return {
      email: '',
      password: '',
      confirmPassword: '',
      fullName: '',
      showing: false,
    }
  },
  computed: {
    ...mapState(['displayError', 'loading']),
    ...mapState({
      paperKey: (state) => state.account.paperKey,
    }),
    passwordsDoNotMatch: function () {
      return this.confirmPassword && this.confirmPassword !== this.password
    },
    showError: function () {
      if (this.passwordsDoNotMatch) {
        return 'Passwords do not match.'
      } else if (this.passwordNotValidError) {
        return this.passwordNotValidError
      } else {
        return this.displayError
      }
    },
    passwordNotValidError: function () {
      if (this.password) {
        return validatePassword(this.password)
      }
      return ''
    },
  },
  methods: {
    ...mapActions(['pushRoute', 'clearErrorMessage']),
    // map `this.registerAndBackupAccount()` to `this.$store.dispatch('registerAndBackupAccount')`
    ...mapActions('account', ['registerAndBackupAccount']),
    async btnRegister() {
      if (
        !this.password ||
        !this.confirmPassword ||
        this.passwordsDoNotMatch ||
        this.passwordNotValidError
      ) {
        return
      } else {
        // validatePassword(this.password)
        const newAccount = {
          password: this.password,
          email: this.email,
          accountName: this.fullName,
        }
        await this.registerAndBackupAccount(newAccount)

        if (this.paperKey) {
          this.showing = true
        }
      }
    },
    routeToLogin() {
      this.pushRoute('/login')
    },
    onContinue() {
      // pass showing false to DialogueBox to re-enabled vertical scrolling on page
      this.showing = false
      this.pushRoute('/')
    },
  },
  /*
    If the user has already received an API error,
    the watcher will clear the error state if the
    user enters new password information.  Without this,
    the user will get past the "Passwords do not match."
    error and see an outdated API error message.
  */

  watch: {
    passwordsDoNotMatch: function (val) {
      if (val === false) {
        this.clearErrorMessage()
      }
    },
  },
}
</script>
<style scoped lang="scss"></style>
